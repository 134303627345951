import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion, usePresence } from 'framer-motion'
import { gsap } from 'gsap'
import './App.scss'
import Carousel from './Carousel'
import { useLocation } from 'react-router-dom'
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'
import '@leenguyen/react-flip-clock-countdown/dist/index.css'
function App() {
    const [coverShow, setCoverShow] = useState(true)
    const [playing, toggle] = useAudio()
    useEffect(() => {
        if (!coverShow) {
            if (!playing) {
                toggle()
            }
        }
    }, [coverShow])

    return (
        <div className="App">
            <CoverSection coverShow={coverShow} setCoverShow={setCoverShow} />
            {!coverShow && (
                <div className="main-section">
                    <Section1 />
                    <Section2 />
                    <Section3 />
                    <Section4 />
                    <Section5 />
                    <Section6 />
                    <Section7 />
                    <Section8 />
                    <Section9 />
                    <Section10 />
                </div>
            )}
        </div>
    )
}
const useAudio = () => {
    const [audio] = useState(new Audio('/song/audio.mp3'))
    const [playing, setPlaying] = useState(false)

    const toggle = () => setPlaying(!playing)

    useEffect(() => {
        audio.loop = true
        audio.currentTime = 16
        playing ? audio.play() : audio.pause()
    }, [playing])

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false))
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false))
        }
    }, [])

    return [playing, toggle]
}

const CoverSection = ({ coverShow, setCoverShow }) => {
    // const [coverShow, setCoverShow] = useState(true)
    const [diundang, setDiundang] = useState('')
    const location = useLocation()
    useEffect(() => {
        const name = location.pathname
            .replace(/_/g, ' ')
            .replace(/[^a-zA-Z0-9\s]/g, '')
        setDiundang(name)
    }, [location])

    const exitAnimation = {
        opacity: 0,
        y: '-100vh',
        transition: { duration: 2, ease: 'easeInOut' }, // Use "easeInOut" for smooth start and end
    }
    return (
        <AnimatePresence>
            {coverShow && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 3 }}
                    exit={exitAnimation}
                    className="cover-inv"
                >
                    <div className="bg-img">
                        <div className="title-cover">
                            <div>
                                <p className="title">The Wedding Of</p>
                            </div>
                            <div className="desc">
                                <p className="name">Samuel & Ruth</p>
                                <span>
                                    <i></i>
                                </span>
                                <p className="date">
                                    16 <b>.</b>
                                    09 <b>.</b>
                                    2024
                                </p>
                                {diundang !== '' && (
                                    <p className="date diundang">
                                        Dear <br />{' '}
                                        <span
                                            style={{
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {diundang}
                                        </span>
                                    </p>
                                )}
                                <button
                                    type="button"
                                    onClick={() => {
                                        setCoverShow(false)
                                    }}
                                    className="btn"
                                >
                                    Buka Undangan
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
// const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`
// const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`

const gradient = (direction, size, mask) =>
    `repeating-linear-gradient(to ${direction}, ` +
    `black 0px, ` +
    `black ${mask ? 0 : size / 2}px, ` +
    `transparent ${mask ? 0 : size / 2}px, ` +
    `transparent ${mask ? size : size / 2}px, ` +
    `black ${mask ? size : size / 2}px, ` +
    `black ${size}px` +
    ')'

const hiddenMask = `${gradient('right', 30, true)}, ${gradient(
    'bottom',
    40,
    true
)}`
const visibleMask = `${gradient('right', 30, false)}, ${gradient(
    'bottom',
    50,
    false
)}`

const Section1 = () => {
    const [isInView, setIsInView] = useState(false)
    return (
        <section className="section1">
            <motion.div
                initial={false}
                animate={
                    isInView
                        ? {
                              WebkitMaskImage: visibleMask,
                              maskImage: visibleMask,
                          }
                        : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                onViewportEnter={() => setIsInView(true)}
                className="bg-img"
            >
                <div className="logo">
                    <p className="logo-txt">
                        <span className="first">S</span>{' '}
                        <span className="and">&amp;</span>{' '}
                        <span className="second">R</span>
                    </p>
                </div>
                <div className="footer">
                    <p className="name">Samuel Zega & Ruth Siregar</p>
                    <p className="desc">
                        "Beyond all these things put on and wrap yourselves in
                        [unselfish] love, which is the perfect bond of unity
                        [for everything is bound together in agreement when each
                        one seeks the best for others]." (Colossians 3:14 AMP)
                    </p>
                    <p className="date">
                        16 <b>.</b> 09 <b>.</b> 2024
                    </p>
                </div>
            </motion.div>
        </section>
    )
}
const Section2 = () => {
    const [isGroomInView, setIsGroomInView] = useState(false)
    const [isBrideInView, setIsBrideInView] = useState(false)
    return (
        <section className="section2">
            <motion.div
                initial={{ opacity: 0, x: '20vw' }}
                animate={isGroomInView && { opacity: 1, x: 0 }}
                transition={{ duration: 2 }}
                viewport={{ once: true }}
                onViewportEnter={() => setIsGroomInView(true)}
                className="groom"
            >
                <div className="header">
                    <div className="photo-frame">
                        <img src="/img/sam.png" alt="photo" />
                    </div>
                    <div className="profile-slider">
                        <p>THE GROOM</p>
                    </div>
                </div>
                <div className="footer">
                    <p className="name">Samuel A. Zega, B.Sc</p>
                    <p className="sonof">
                        <b>The First son of :</b>
                    </p>
                    <p className="parent">Lukas Zega</p>
                    <p className="parent">Rospita Sihombing</p>
                    <div className="instagram">
                        <p>
                            <a
                                href="https://www.instagram.com/samuelzega14"
                                target="_blank"
                            >
                                <Instagram />
                                <span className="username">@samuelzega14</span>
                            </a>
                        </p>
                    </div>
                </div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, x: '-20vw' }}
                animate={isBrideInView && { opacity: 1, x: 0 }}
                transition={{ duration: 3 }}
                viewport={{ once: true }}
                onViewportEnter={() => setIsBrideInView(true)}
                className="bride"
            >
                <div className="header">
                    <div className="photo-frame">
                        <img src="/img/ruthone.jpg" alt="photo" />
                    </div>
                    <div className="profile-slider">
                        <p>THE BRIDE</p>
                    </div>
                </div>
                <div className="footer">
                    <p className="name">Ruth Kiris D. Siregar, S.M</p>
                    <p className="sonof">
                        <b>The Third daughter of :</b>
                    </p>
                    <p className="parent">Pungka Siregar</p>
                    <p className="parent">Retti Nababan</p>
                    <div className="instagram">
                        <p>
                            <a
                                href="https://www.instagram.com/ruthsiregar14"
                                target="_blank"
                            >
                                <Instagram />
                                <span className="username">@ruthsiregar14</span>
                            </a>
                        </p>
                    </div>
                </div>
            </motion.div>
        </section>
    )
}

const Section3 = () => {
    return (
        <div className="section3">
            <p class="journey-text">Photo Gallery</p>
            <div className="gallery">
                <img src="/img/one.png" alt="a house on a mountain" />
                <img src="/img/two.png" alt="sime pink flowers" />
                <img src="/img/three.png" alt="big rocks with some trees" />
                <img
                    src="/img/four.png"
                    alt="a waterfall, a lot of tree and a great view from the sky"
                />
                <img src="/img/ten.png" alt="a cool landscape" />
                <img
                    src="/img/six.png"
                    alt="inside a town between two big buildings"
                />
                <img
                    src="/img/seven.png"
                    alt="a great view of the sea above the mountain"
                />
            </div>
        </div>
    )
}

const Section4 = () => {
    return (
        <div className="section4">
            {/* <p class="journey-text">Precious Moment</p> */}
            <div>
                {/* <span className="devider"></span> */}
                <div style={{ position: 'relative', pointerEvents: 'none' }}>
                    <video
                        style={{ width: '100vw' }}
                        src={'/video/vid.mov'}
                        autoplay="true"
                        loop
                        muted
                        playsInline
                        preload="auto"
                    />
                    <p className="text-desc">
                        "I believe that God created you for me to love. He
                        picked you out from all the rest cause. He Knew I'd love
                        you the best."
                    </p>
                </div>
                {/* <span style={{ marginTop: '2rem' }} className="devider"></span> */}
            </div>
        </div>
    )
}

const Section5 = () => {
    return (
        <div className="section5">
            <div className="header">
                <div>
                    <p>{`The wedding will be held at`}</p>
                    <div className="devider"></div>
                </div>
            </div>
            <div className="content">
                <div className="sec">
                    <p className="title">Holy Matrimony</p>
                    <p className="date">Monday, September 16th, 2024</p>
                    <p className="clock">at 10:00 WIB - finish</p>
                    <a
                        className="map"
                        href="https://maps.app.goo.gl/YgwCzQUKD2xP4Dtw9"
                        target="_blank"
                    >
                        <p>JKI Tiberias Balige, Jl Dr. Sutomo Balige</p>
                    </a>
                </div>
                <div className="sec">
                    <p className="title">Wedding Reception</p>
                    <p className="date">Monday, September 16th, 2024</p>
                    <p className="clock">at 12:30 WIB - finish</p>
                    <a
                        className="map"
                        href="https://maps.app.goo.gl/JoxSRioX1UdCVQ2J6"
                        target="_blank"
                    >
                        <p>Gedung Serbaguna HKBP Balige</p>
                    </a>
                </div>

                <a
                    href=""
                    rel="noopener"
                    target="_blank"
                    class="savetocalendar"
                >
                    save event to calendar
                </a>
                <FlipClockCountdown
                    style={{
                        marginTop: '3rem',
                        marginBottom: '-4rem',
                        marginLeft: '1rem',
                    }}
                    to={new Date(
                        new Date('2024-09-16T22:00:00').toLocaleString(
                            'en-US',
                            { timeZone: 'Asia/Jakarta' }
                        )
                    ).getTime()}
                    // labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']}
                    labelStyle={{
                        //     fontSize: 8,
                        //     fontWeight: 500,
                        //     textTransform: 'uppercase',
                        //     backgroundColor: 'green'
                        color: '#758074',
                        fontWeight: 'bold',
                    }}
                    digitBlockStyle={{
                        backgroundColor: '#758074',
                        width: '8vw',
                        height: '12vw',
                        fontSize: '8vw',
                    }}
                    dividerStyle={{ color: '#eceae1', height: 1 }}
                    separatorStyle={{ color: '#758074', size: '6px' }}
                    duration={0.5}
                >
                    Wedding is done
                </FlipClockCountdown>
            </div>
        </div>
    )
}

const Section6 = () => {
    return (
        <div className="section6">
            <p className="text">Maps</p>
            <div className="gmaps">
                <iframe
                    src="https://maps.google.com/maps?q=Gereja+Tiberias+Balige&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    width="600"
                    height="450"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                ></iframe>
            </div>

            <a className="seelocation">See Location</a>
        </div>
    )
}

const Section7 = () => {
    return (
        <div className="section7">
            <div className="header">
                <img
                    className="img1"
                    src="/img/ruthprofileleft.jpg"
                    alt="photo"
                />
                <img className="img2" src="/img/samfooter.jpg" alt="photo" />
            </div>
            <div className="desc">
                <p className="title">Live Streaming</p>
                <p className="detail">
                    Meet us <b>virtually</b> to witness our wedding
                </p>
                <a
                    href="#"
                    // rel="noopener"
                    // target="_blank"
                    class="strm-btn"
                >
                    go to streaming
                </a>
            </div>
        </div>
    )
}
const Section8 = () => {
    const [isOn, setIsOn] = useState(false)

    const toggleSwitch = () => setIsOn(!isOn)

    return (
        <div className="section8">
            <p className="title">Send your gift</p>
            <div className="content">
                <div className="gift-box">
                    <p className="ttl">
                        Give your <b>gift</b> to the bride and groom
                    </p>
                    <div className="switch-box">
                        Direct Transfer
                        <div
                            className="switch"
                            data-isOn={isOn}
                            onClick={toggleSwitch}
                        >
                            <motion.div
                                className="handle"
                                layout
                                transition={{
                                    type: 'spring',
                                    stiffness: 700,
                                    damping: 30,
                                }}
                            />
                        </div>
                        Send Gift
                    </div>
                </div>
                {isOn ? (
                    <div className="detail-box">
                        <p className="detail-title">
                            Send your gift to this location:
                        </p>
                        <ul>
                            <li>Sopo Duru</li>
                            <li style={{ margin: '5px 0' }}>
                                <b>no. 9j</b>{' '}
                                {/* <a
                                    data-clipboard-text="18181818718"
                                    href="#"
                                    target="_self"
                                >
                                    <svg
                                        viewBox="0 0 16 16"
                                        width="1em"
                                        height="1em"
                                        focusable="false"
                                        role="img"
                                        aria-label="files"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        class="bi-files b-icon bi"
                                    >
                                        <g>
                                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"></path>
                                        </g>
                                    </svg>
                                </a> */}
                            </li>
                            <li>Rawa Buntu, Serpong, Tangerang Selatan</li>
                        </ul>{' '}
                    </div>
                ) : (
                    <div className="detail-box">
                        <p className="detail-title">
                            Transfer directly to the account below:
                        </p>
                        <ul>
                            <li>Bank OCBC</li>
                            <li style={{ margin: '5px 0' }}>
                                <b>693815688892</b>{' '}
                                <a
                                    data-clipboard-text="693815688892"
                                    href="#"
                                    target="_self"
                                >
                                    <svg
                                        viewBox="0 0 16 16"
                                        width="1em"
                                        height="1em"
                                        focusable="false"
                                        role="img"
                                        aria-label="files"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        class="bi-files b-icon bi"
                                    >
                                        <g>
                                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>a/n Samuel Anugerah Zega</li>
                        </ul>
                        <ul>
                            <li>Bank BCA</li>
                            <li style={{ margin: '5px 0' }}>
                                <b>0030887557</b>{' '}
                                <a
                                    data-clipboard-text="0030887557"
                                    href="#"
                                    target="_self"
                                >
                                    <svg
                                        viewBox="0 0 16 16"
                                        width="1em"
                                        height="1em"
                                        focusable="false"
                                        role="img"
                                        aria-label="files"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        class="bi-files b-icon bi"
                                    >
                                        <g>
                                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>a/n Ruth Kiris D. Siregar</li>
                        </ul>
                        <ul>
                            <li>Bank BRI</li>
                            <li style={{ margin: '5px 0' }}>
                                <b>067101396726509</b>{' '}
                                <a
                                    data-clipboard-text="067101396726509"
                                    href="#"
                                    target="_self"
                                >
                                    <svg
                                        viewBox="0 0 16 16"
                                        width="1em"
                                        height="1em"
                                        focusable="false"
                                        role="img"
                                        aria-label="files"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        class="bi-files b-icon bi"
                                    >
                                        <g>
                                            <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>a/n Samuel Anugerah Zega</li>
                        </ul>
                    </div>
                )}
                <div className="sweetie-box"></div>
            </div>
        </div>
    )
}
const Section9 = () => {
    return (
        <div className="section9">
            <div className="header">
                <img className="footer-img" src="/img/footer.jpg" alt="photo" />
                <div className="footer-backdrop"></div>
            </div>
            <div className="footer">
                <p className="text">
                    We are honored and delighted if Mr/Mrs/Brother/Sister could
                    attend to give your blessings at our wedding. We sincerely
                    thank you for your presence and the prayers you offer
                </p>
                <p className="name">Samuel &amp; Ruth</p>
            </div>
        </div>
    )
}
const Section10 = () => {
    return (
        <div className="section10">
            <p>
                Powered by
                <span>Zegasoft</span>
            </p>
            <div>
                <a
                    href="https://www.instagram.com/zegasoft/"
                    target="blank"
                    class="border-fifth font-content"
                >
                    <svg
                        viewBox="0 0 16 16"
                        width="1em"
                        height="1em"
                        focusable="false"
                        role="img"
                        aria-label="instagram"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi-instagram color-fifth b-icon bi"
                    >
                        <g>
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                        </g>
                    </svg>
                </a>{' '}
                <a href="https://zegasoft.id" target="blank">
                    <svg
                        viewBox="0 0 16 16"
                        width="1em"
                        height="1em"
                        focusable="false"
                        role="img"
                        aria-label="globe"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi-globe color-fifth font-content b-icon bi"
                    >
                        <g>
                            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"></path>
                        </g>
                    </svg>
                </a>
            </div>
        </div>
    )
}

const Instagram = () => (
    <svg
        viewBox="0 0 16 16"
        width=".8em"
        height=".8em"
        focusable="false"
        role="img"
        aria-label="instagram"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi-instagram mr-2 b-icon bi"
    >
        <g>
            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
        </g>
    </svg>
)
const images = [
    'https://images.pexels.com/photos/169647/pexels-photo-169647.jpeg?auto=compress&cs=tinysrgb&w=600',
    'https://images.pexels.com/photos/313782/pexels-photo-313782.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/773471/pexels-photo-773471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/672532/pexels-photo-672532.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/632522/pexels-photo-632522.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    'https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
]
export default App
